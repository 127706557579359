import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = props => {
    return (
        <div>
            
            <div className="container"></div>

                <p className="title">
                    Maas development 
                </p>
                <p className="sub">
                    Is currently under construction
                </p>
                <hr className="line"/>
                <p className="comp">
                    Current progression:
                </p>
                <div class="Loading"></div>
                <hr className="line"/>

                <div className="cont2">
                    <a className="link" href="https://www.linkedin.com/in/stefanmaasdev" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </div>

        </div>
        
    )
}

export default Home;